import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { Col, ListGroup, Nav, Row, Tab, Form } from 'react-bootstrap';
import {
  DefaultBtn,
  DefaultModal,
  SimpleCenteredModal,
  Slides
} from '../../components';
import { sendAlert } from '../../actions/utils';
import { validateToken } from '../../actions/auth';
import { createRedeemRequest } from '../../requests/redeems';
import { formatToPoints } from '../../services/utils';
import baseColors from '../../assets/stylesheets/base_colors';

const ProductInfo = props => {
  const [modalBody, setModalBody] = useState('');
  const [address, setAddress] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [noPointsModalShow, setNoPointsModalShow] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { product } = props;
  const {
    name,
    description,
    price,
    stock,
    specification,
    dispatch: productDispatch
  } = product;
  const { user } = useSelector(state => state.auth);
  const {
    settings: { nameWord }
  } = useSelector(state => state.utils);

  const handleModalShow = () => {
    setModalShow(true);
    setAddress(user.address);
    setModalBody(
      <div>
        <p>¿Estás seguro que deseas canjear este producto?</p>
        <p>
          Ingresa la dirección donde se debe realizar el despacho del producto:
        </p>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="text"
              placeholder="Dirección"
              name="address"
              defaultValue={user.address}
              onChange={e => setAddress(e.target.value)}
            />
            <Form.Text className="text-muted">
              Si quieres cambiar la dirección de despacho de forma permanente
              puedes hacerlo editando tu dirección
              <Link to="/profile/edit"> AQUÍ.</Link>
            </Form.Text>
          </Form.Group>
        </Form>
      </div>
    );
  };

  const handleNoPointsModalShow = () => {
    setNoPointsModalShow(true);
    setModalBody(`No tienes suficientes ${nameWord}`);
  };

  const handleSuccessCreate = () => {
    dispatch(
      sendAlert({ kind: 'success', message: 'Producto canjeado con éxito' })
    );

    dispatch(validateToken());
    history.push('/products');
  };

  const handleFailureRequest = error => {
    dispatch(
      sendAlert({ kind: 'error', message: error?.response?.data?.message })
    );
  };

  const handleCreateRedeemRequest = () => {
    createRedeemRequest(product.id, {
      params: {
        productId: product.id,
        address
      },
      dispatch,
      successCallback: handleSuccessCreate,
      failureCallback: handleFailureRequest
    });
    setModalShow(false);
  };

  const missingPoints = () => {
    const substractBalance = user.balance - price;
    if (substractBalance < 0) {
      return Math.abs(substractBalance);
    }
    return 0;
  };

  const redeemButton = () => {
    if (user.balance < price) {
      return (
        <DefaultBtn
          className="nn-no-redeem-btn"
          onClick={handleNoPointsModalShow}
        >
          CANJEAR
        </DefaultBtn>
      );
    }
    if (stock < 1) {
      return (
        <DefaultBtn
          className="nn-no-redeem-btn disabled"
          onClick={handleNoPointsModalShow}
        >
          SIN STOCK
        </DefaultBtn>
      );
    }
    return (
      <DefaultBtn
        className="btn-primary nn-redeem-btn "
        onClick={handleModalShow}
      >
        CANJEAR
      </DefaultBtn>
    );
  };

  return (
    <div id="nn-product-info">
      <DefaultModal
        title="Confirmar Canje"
        body={modalBody}
        show={modalShow}
        handleClose={() => setModalShow(false)}
        handleConfirm={handleCreateRedeemRequest}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />

      <SimpleCenteredModal
        centered={false}
        header="Modal"
        title="Atención"
        body={modalBody}
        show={noPointsModalShow}
        titlebtn="close"
        titlebtnmodal="Modal centrado"
        handleClose={() => setNoPointsModalShow(false)}
        onHide={() => setNoPointsModalShow(false)}
        closeButton
      />

      {product && (
        <Row>
          <Col md={{ span: 7 }} xs={{ span: 12 }}>
            <Row className="mb-3">
              <Col>
                <Slides
                  items={[product]}
                  specific="product-show-carousel"
                  classNameImg="product-show-img-carousel"
                />
              </Col>
            </Row>
          </Col>

          <Col className="mb-4" md={{}} xs={{ span: 12 }}>
            <h2>{name}</h2>
            <p>{description}</p>
            <p className="d-flex flex-wrap">
              <div className="mr-2 my-auto nn-circle" />
              <p
                id="product-points"
                className="my-auto reward-text"
                style={{ color: baseColors.colorTextPointsProducts }}
              >
                {formatToPoints(price)} {nameWord}
              </p>
            </p>

            {redeemButton()}

            <ListGroup horizontal className="mt-3 price-detail">
              <ListGroup.Item className="price-detail-item">
                <p>Precio</p>
                <b>{price}</b>
              </ListGroup.Item>
              <ListGroup.Item className="price-detail-item">
                <p>Tus {nameWord}</p>
                <b id="user-points">{user.balance}</b>
              </ListGroup.Item>
              <ListGroup.Item className="price-detail-item">
                <p>Te faltan</p>
                <b>{missingPoints()}</b>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      )}
      <div className="product-info-container-tab">
        <Tab.Container
          className="tab-container"
          defaultActiveKey="tab-specifications"
        >
          <Nav variant="tabs">
            <Nav.Item className="tab-label">
              <Nav.Link eventKey="tab-specifications">
                Especificaciones
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="tab-label">
              <Nav.Link eventKey="tab-dispatch">Despacho</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="tab-specifications">
              <p className="mt-2">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      specification ||
                      'Este producto no cuenta con especificaciones'
                  }}
                />
              </p>
            </Tab.Pane>
            <Tab.Pane eventKey="tab-dispatch">
              <p className="mt-2">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      productDispatch ||
                      'Este producto no cuenta con especificaciones de despacho'
                  }}
                />
              </p>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
};

export default ProductInfo;
