import React from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { es } from 'date-fns/locale';
import './styles.scss';

const FormikDatePicker = ({
  abbr,
  date,
  errorText,
  focused,
  helpText,
  id,
  inputType,
  label,
  margin,
  onDateChange,
  onFocusChange,
  placeholder,
  readOnlyInput,
  size,
  disableOutsideRange,
  ...props
}) => (
  <Form.Group className={margin}>
    {label ? (
      <Form.Label>
        {label} {abbr ? <abbr className="text-danger">*</abbr> : false}
      </Form.Label>
    ) : (
      false
    )}
    <br />
    <DatePicker
      className="form-control"
      date={date}
      onDateChange={selectedDate => onDateChange(selectedDate)}
      placeholderText={placeholder}
      locale={es}
      {...props}
    />
  </Form.Group>
);

export default FormikDatePicker;
