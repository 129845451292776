import ApiService from '../services/apiService';

export const showBenefitRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/benefits/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexBenefitsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) => {
  ApiService.request('get', '/benefits', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};
