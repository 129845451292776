import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

const Loader = ({ height, variant, minHeight }) => {
  return (
    <div
      className="containerSpinnerLoad"
      style={{ position: 'relative', height, minHeight }}
    >
      <Spinner animation="border" variant={variant} />
    </div>
  );
};

Loader.propTypes = {
  variant: PropTypes.string,
  height: PropTypes.string,
  minHeight: PropTypes.string
};

Loader.defaultProps = {
  variant: 'secondary',
  height: '90%',
  minHeight: '300px'
};

export default Loader;
