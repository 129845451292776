import React from 'react';
import PropTypes from 'prop-types';
import {
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown
} from 'react-bootstrap';

const InputDropdown = ({ items, variant, title, ariaDescribedby, size }) => (
  <InputGroup className="mb-3" size={size}>
    <DropdownButton
      as={InputGroup.Prepend}
      variant={variant}
      title={title}
      id="input-group-dropdown-1"
    >
      {items.map(item => (
        <Dropdown.Item href={item.path} key={item.key}>
          {item.text}
        </Dropdown.Item>
      ))}
    </DropdownButton>
    <FormControl aria-describedby={ariaDescribedby} />
  </InputGroup>
);

InputDropdown.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string.isRequired,
  ariaDescribedby: PropTypes.string,
  size: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      text: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired
    })
  ).isRequired
};

InputDropdown.defaultProps = {
  variant: 'info',
  ariaDescribedby: null,
  size: 'md'
};

export default InputDropdown;
