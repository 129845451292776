import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import ProductInfo from './ProductInfo';
import { BackBtn, Loader } from '../../components';
import { showProductRequest } from '../../requests/products';

const ProductShow = props => {
  const [onRequest, setOnRequest] = useState(true);
  const [product, setProduct] = useState({
    id: '',
    fileInfo: '',
    position: 0,
    caption: '',
    active: ''
  });

  const {
    settings: { menuType }
  } = useSelector(state => state.utils);

  const handleSuccessShow = response => {
    const _product = camelCaseRecursive(response.data);
    setProduct(_product);
  };

  useEffect(() => {
    const { match, dispatch } = props;
    const productId = match.params.id;
    setOnRequest(true);
    showProductRequest(productId, {
      dispatch,
      successCallback: response => {
        handleSuccessShow(response);
        setOnRequest(false);
      }
    });
  }, [props]);

  return onRequest ? (
    <Loader />
  ) : (
    <>
      <Row className="justify-content-center">
        <Col md={menuType === 1 ? 10 : 12}>
          <BackBtn route="/products" className="mb-2 ml-n3" />
          <ProductInfo product={product} />
        </Col>
      </Row>
    </>
  );
};

export default connect()(ProductShow);
