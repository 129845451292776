import React from 'react';
import { useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';
import Loader from '../Loader';

const MainLogo = ({ className, header, ...props }) => {
  const {
    settings,
    ongoingRequest: { getSettings }
  } = useSelector(state => state.utils);
  const { logo, headerLogo } = settings;

  const renderImage = () => {
    if (header) {
      return (
        <>
          {headerLogo ? (
            <Image
              {...props}
              src={headerLogo}
              alt="Logo"
              className={`d-inline-block align-top ${className}`}
            />
          ) : null}
        </>
      );
    }
    return (
      <>
        {logo ? (
          <Image
            {...props}
            src={logo}
            alt="Logo"
            className={`d-inline-block align-top ${className}`}
          />
        ) : null}
      </>
    );
  };

  return <>{getSettings ? <Loader minHeight="0px" /> : renderImage()}</>;
};

export default MainLogo;
