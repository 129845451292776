import React from 'react';
import DataTable from 'react-data-table-component';
import baseColors from '../../../assets/stylesheets/base_colors';

const BasicDataTable = ({
  columns,
  data,
  expandable = false,
  expandableComponent = null
}) => {
  return (
    <DataTable
      title=""
      columns={columns}
      data={data}
      pagination
      noHeader
      expandableRows={expandable}
      expandOnRowClicked={expandable}
      expandableRowsComponent={expandableComponent}
      highlightOnHover
      paginationComponentOptions={{
        rowsPerPageText: 'Filas por pagina:',
        rangeSeparatorText: 'de'
      }}
      customStyles={{
        headCells: {
          style: {
            border: 'none',
            fontWeight: 'bold !important',
            fontSize: '14px',
            backgroundColor: baseColors.bgDatatable,
            color: baseColors.colorDatatable
          }
        },
        cells: {
          style: {
            backgroundColor: baseColors.bgDatatable,
            color: baseColors.colorDatatable
          }
        }
      }}
    />
  );
};

export default BasicDataTable;
