import React from 'react';
import { Navbar } from 'react-bootstrap';
import MainLogo from '../MainLogo';

const PrincipalNavbar = ({ children, onClick }) => (
  <Navbar variant="dark" expand="lg" fixed="top">
    {onClick && <Navbar.Toggle onClick={onClick} />}
    <Navbar.Brand className="nav-brand">
      <MainLogo header className="nav-logo" />
    </Navbar.Brand>

    {children}
  </Navbar>
);

export default PrincipalNavbar;
