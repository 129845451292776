import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import './styles.scss';

const RankingRow = ({ data, firstLetter }) => {
  return (
    <div className="container-ranking-row">
      <Row>
        <Col className="title-td-ranking without-padding-r">Rent</Col>
        <Col className="title-td-ranking without-padding-r">Contac</Col>
        <Col className="title-td-ranking without-padding-r">Efect. VR</Col>
        <Col className="title-td-ranking without-padding-r">Desafíos</Col>
      </Row>
      <Row>
        <Col className="without-padding-r">
          <Badge className="badge-gray">
            Total {data.kpi1} {firstLetter}
          </Badge>
        </Col>
        <Col className="without-padding-r">
          <Badge className="badge-gray">
            Total {data.kpi2} {firstLetter}
          </Badge>
        </Col>
        <Col className="without-padding-r">
          <Badge className="badge-gray">
            Total {data.kpi3} {firstLetter}
          </Badge>
        </Col>
        <Col className="without-padding-r">
          <Badge className="badge-gray">
            Total {data.challenges} {firstLetter}
          </Badge>
        </Col>
      </Row>
    </div>
  );
};

export default RankingRow;
