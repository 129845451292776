import camelCaseRecursive from 'camelcase-keys-recursive';
import { utilsTypes } from '../actions/utils';

const initialState = {
  settings: {
    mainContactEmail: ''
  },
  slides: [],
  page: {
    body: null
  },
  alert: {
    show: false,
    title: '',
    kind: '',
    timeout: 3000,
    message: ''
  },
  unreadChallenges: 0,
  unreadNews: 0,
  ongoingRequest: {
    getUnreadChallenges: false,
    getUnreadNews: false,
    getSettings: true,
    getSlides: false,
    getPage: false,
    getNotification: true
  },
  navbarNotifications: [],
  unreadNotifications: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case utilsTypes.EMPTY_UNREAD_CHALLENGES:
      return {
        ...state,
        unreadChallenges: 0,
        ongoingRequest: { ...state.ongoingRequest }
      };
    case utilsTypes.GET_UNREAD_CHALLENGES_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getUnreadChallenges: true }
      };
    case utilsTypes.GET_UNREAD_CHALLENGES_SUCCESS:
      return {
        ...state,
        unreadChallenges: action.result.metadata.amount,
        ongoingRequest: { ...state.ongoingRequest, getUnreadChallenges: false }
      };
    case utilsTypes.GET_UNREAD_CHALLENGES_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getUnreadChallenges: false }
      };
    case utilsTypes.EMPTY_UNREAD_NEWS:
      return {
        ...state,
        unreadNews: 0,
        ongoingRequest: { ...state.ongoingRequest }
      };
    case utilsTypes.GET_UNREAD_NEWS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getUnreadNews: true }
      };
    case utilsTypes.GET_UNREAD_NEWS_SUCCESS:
      return {
        ...state,
        unreadNews: action.result.metadata.amount,
        ongoingRequest: { ...state.ongoingRequest, getUnreadNews: false }
      };
    case utilsTypes.GET_UNREAD_NEWS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getUnreadNews: false }
      };
    case utilsTypes.GET_SETTINGS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSettings: true }
      };
    case utilsTypes.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: camelCaseRecursive(action.result),
        ongoingRequest: { ...state.ongoingRequest, getSettings: false }
      };
    case utilsTypes.GET_SETTINGS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSettings: false }
      };
    case utilsTypes.GET_SLIDES_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSlides: true }
      };
    case utilsTypes.GET_SLIDES_SUCCESS:
      return {
        ...state,
        slides: action.result,
        ongoingRequest: { ...state.ongoingRequest, getSlides: false }
      };
    case utilsTypes.GET_SLIDES_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getSlides: false }
      };
    case utilsTypes.GET_PAGE_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getPage: true },
        url: action.url
      };
    case utilsTypes.GET_PAGE_SUCCESS:
      return {
        ...state,
        page: action.result,
        ongoingRequest: { ...state.ongoingRequest, getPage: false }
      };
    case utilsTypes.GET_PAGE_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getPage: false }
      };
    case utilsTypes.SET_ALERT:
      return {
        ...state,
        alert: {
          show: true,
          kind: action.kind,
          timeout: action.timeout || initialState.alert.timeout,
          message: action.message,
          title: action.title
        }
      };
    case utilsTypes.CLOSE_ALERT:
      return {
        ...state,
        alert: initialState.alert
      };
    case utilsTypes.GET_LAST_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getNotification: true }
      };
    case utilsTypes.GET_LAST_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        navbarNotifications: camelCaseRecursive(action.result.data),
        ongoingRequest: { ...state.ongoingRequest, getNotification: false }
      };
    case utilsTypes.GET_LAST_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        ongoingRequest: { ...state.ongoingRequest, getNotification: false }
      };
    case utilsTypes.GET_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        unreadNotifications: action.result.metadata.amount
      };
    default:
      return state;
  }
};

export default reducer;
