import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { useLocation } from 'react-router-dom';
import { BackBtn, Loader } from '../../components';
import { indexNewsRequest, showNewsRequest } from '../../requests/news';
import NewsCard from './NewsCard';

const NewsShow = props => {
  const {
    settings: { hideMoreNews }
  } = useSelector(state => state.utils);
  const location = useLocation();
  const isPrizePath = location.pathname.split('=')[1] === 'prize';
  const {
    settings: { menuType, newsTitle }
  } = useSelector(state => state.utils);

  const [onRequest, setOnRequest] = useState(true);
  const [news, setNews] = useState({
    id: '',
    title: '',
    bodyContent: '',
    publicationDate: '',
    fileInfo: {},
    bannerInfo: {}
  });
  const [moreNews, setMoreNews] = useState([]);

  const redirectBackRoute = () => {
    if (isPrizePath) {
      return '/prize';
    }
    return '/news';
  };

  const handleSuccessShow = response => {
    const _news = camelCaseRecursive(response.data);
    setNews(_news);
  };

  const displayContent = htmlContent => {
    if (htmlContent) {
      return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    }
    return <h4>404</h4>;
  };

  const renderMoreNews = () =>
    moreNews.map(n => (
      <Col md={6}>
        <NewsCard item={n} newsType={!isPrizePath} prizeType={isPrizePath} />
      </Col>
    ));

  useEffect(() => {
    const { match, dispatch } = props;
    const newsId = match.params.id;
    setOnRequest(true);
    showNewsRequest(newsId, {
      dispatch,
      successCallback: response => handleSuccessShow(response)
    });

    indexNewsRequest({
      dispatch,
      params: {
        news_type: isPrizePath ? 1 : 0
      },
      successCallback: result => {
        const _moreNews = camelCaseRecursive(result.data.data);
        setMoreNews(_moreNews.filter(n => n.id !== Number(newsId)).slice(0, 2));
        setOnRequest(false);
      }
    });
  }, [props]);

  return onRequest ? (
    <Loader />
  ) : (
    <Row className="justify-content-center">
      <Col md={menuType === 1 ? 10 : 12}>
        <BackBtn route={redirectBackRoute()} className="mb-2 ml-n3" />
        <Row>
          <Col>
            <h2 className="screen-title">{news.title}</h2>
            <p>{news.lede}</p>
          </Col>
          <Col md={{ span: 4 }} style={{ textAlign: 'end' }}>
            {/* <p>{news.publicationDate}</p> */}
          </Col>
        </Row>
        {Object.keys(news?.bannerInfo).length > 0 && (
          <Row className="mt-2">
            <Col md={12}>
              <img
                alt={news.title}
                src={news.bannerInfo?.fileUrl}
                className="w-100 banner-news"
              />
            </Col>
          </Row>
        )}
        <Row className="mt-4">
          <Col className="news-paragraph">
            {displayContent(news.bodyContent)}
          </Col>
        </Row>
        {hideMoreNews === 1 ? null : (
          <>
            {moreNews.length > 0 && (
              <>
                <Col className="d-flex my-3">
                  <h4 className="screen-title">
                    Otras {newsTitle || 'noticias'} que pueden interesarte
                  </h4>
                </Col>
                <Row>{renderMoreNews()}</Row>
              </>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

export default connect()(NewsShow);
