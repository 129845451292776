import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import ChallengeCard from './ChallengeCard';
import { FullCard } from '../../components';

const ChallengesList = ({ challenges, trivia, contest, contest2 }) => {
  const {
    settings: { fullCard }
  } = useSelector(state => state.utils);

  return (
    <>
      <Row id="nn-challenges-wrapper" className="my-4">
        {challenges.map(challenge => {
          if (fullCard === 1) {
            return (
              <Col key={challenge.id} xs={12}>
                <FullCard
                  imageURL={challenge.imageUrl}
                  title={challenge.title}
                  subtitle={challenge.summary}
                  description={challenge.description}
                  item={challenge}
                  trivia={trivia}
                  contest={contest}
                  contest2={contest2}
                />
              </Col>
            );
          }
          return (
            <Col
              key={challenge.id}
              xs={6}
              md={6}
              lg={3}
              className="mobile-columns"
            >
              <ChallengeCard
                item={challenge}
                trivia={trivia}
                contest={contest}
                contest2={contest2}
              />
            </Col>
          );
        })}
      </Row>
    </>
  );
};

ChallengesList.propTypes = {
  challenges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
      reward: PropTypes.number,
      imageUrl: PropTypes.string,
      summary: PropTypes.string
    })
  ).isRequired
};

export default ChallengesList;
