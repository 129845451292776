import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Product from './ProductCard';
import { Loader } from '../../components';

const ProductsList = ({ products, loading }) => (
  <>
    <Row id="nn-products-wrapper" className="my-4">
      {loading ? (
        <Loader />
      ) : (
        products.map(item => (
          <Col xs={6} md={6} lg={3} key={item.id} className="mobile-columns">
            <Product item={item} />
          </Col>
        ))
      )}
    </Row>
  </>
);

ProductsList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
      reward: PropTypes.number,
      imageUrl: PropTypes.string,
      summary: PropTypes.string
    })
  ).isRequired
};

export default ProductsList;
