import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './styles.scss';
import { useSelector } from 'react-redux';
import { formatToPoints } from '../../services/utils';
import baseColors from '../../assets/stylesheets/base_colors';

const ProductCard = ({ item }) => {
  const {
    settings: { nameWord }
  } = useSelector(state => state.utils);
  return (
    <div
      key={item.id}
      className="card border-light card-container card-product my-3 mx-md-1"
    >
      <img
        src={item.fileInfo.fileUrl}
        className="card-img-top img-product"
        alt="..."
      />
      <div className="position-relative container-vertical-line">
        <div
          className="text-center category-product position-absolute"
          style={{
            backgroundColor: item.productCategory
              ? item.productCategory.color
              : baseColors.secondary
          }}
        >
          {item.productCategory ? item.productCategory.name : 'Categoría'}
        </div>
      </div>
      <div className="card-body body-product">
        <div className="card-title mt-n2">{item.name}</div>
      </div>
      <div className="redeem-btn btn-products">
        <div className="card-body-text mb-n2 d-flex justify-content-start">
          <div className="mr-1 my-auto nn-circle position-relative circle-points" />
          <p
            className="mt-3 reward-text point-title"
            style={{ color: baseColors.colorTextPointsProducts }}
          >
            {formatToPoints(item.price)} {nameWord}
          </p>
        </div>
        <Button
          block
          as={Link}
          className="btn-primary btn-circle btn-xl nn-product-link position-relative"
          to={`/products/${item.id}`}
        >
          VER MÁS
        </Button>
      </div>
    </div>
  );
};
ProductCard.propTypes = {
  item: PropTypes.shape({
    description: PropTypes.string,
    fileInfo: PropTypes.shape({
      fileUrl: PropTypes.string,
      id: PropTypes.number
    }).isRequired,
    productCategory: PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string
    }),
    specific: PropTypes.string,
    id: PropTypes.number.isRequired
  }).isRequired
};

export default ProductCard;
