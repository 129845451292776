import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const FullCard = ({
  item,
  title,
  subtitle,
  description,
  imageURL,
  newsType,
  prizeType,
  trivia,
  contest,
  contest2
}) => {
  const redirectRoute = () => {
    if (newsType) return `/news/${item.id}`;
    if (prizeType) return `/prize/${item.id}=prize`;
    if (trivia) return `/trivia/${item.id}=trivia`;
    if (contest2) return `/v2_contest/${item.id}=contest2`;
    if (contest) return `/contest/${item.id}=contest`;
    if (!trivia && !contest) return `/challenges/${item.id}`;

    return '/';
  };

  const buttonName = () => {
    return 'LEER MÁS...';
  };

  return (
    <div key={item.id} className="card border-light full-card my-3">
      <div className="image-container d-flex flex-column">
        <img src={imageURL} alt="..." />
        <div className="d-flex flex-column px-4 py-3 w-100">
          <h5 className="mb-4">{title}</h5>
          <p className="subtitle card-body-text">{subtitle}</p>
          <div
            className="container-description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className="bg-gradient" />
          <Button
            as={Link}
            to={redirectRoute()}
            className="btn-primary btn-circle px-3 py-2 mt-auto position-relative align-self-start"
          >
            {buttonName()}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FullCard;
