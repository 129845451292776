import { call, put, race, delay } from 'redux-saga/effects';
import jwt from 'jwt-decode';
import API_CONFIG from '../config/configurations';
import { authTypes } from '../actions/auth';
import { utilsTypes } from '../actions/utils';

const { globalTimeout, timeoutMessage } = API_CONFIG;

export function* updateJWT(authorization) {
  localStorage.setItem('jwt', authorization);
  yield put({
    type: authTypes.SET_INFO_USER,
    result: jwt(authorization)
  });
}

const getHeader = headers => {
  let header = '';
  if (headers.constructor === Map) {
    header = headers.get('Authorization');
  } else {
    header = headers.authorization;
  }

  return header.split('Bearer ')[1];
};

export function* setUserHeaders(headers) {
  try {
    const authorization = getHeader(headers);
    if (authorization) {
      if (localStorage.getItem('jwt')) {
        yield updateJWT(authorization);
      }
    }
  } catch (error) {
    // localStorage.removeItem('jwt');
  }
}

function* runDefaultSaga(callRequest, successCallback, failureCallback) {
  try {
    const { response, timeout } = yield race({
      response: call(callRequest.request, callRequest.params),
      timeout: delay(globalTimeout)
    });

    if (timeout) throw new Error(timeoutMessage);

    if (response.ok) {
      const result =
        response.status === 204 ? { success: true } : yield response.json();
      yield setUserHeaders(response.headers);
      yield successCallback(result, response, callRequest.params);
    } else if (response.status === 401) {
      yield response.json().then(data => {
        throw new Error(
          data.error || 'Ocurrió un problema en la autenticación'
        );
      });
    } else if (response.status === 403) {
      yield response.json().then(data => {
        throw new Error(
          data.error || 'Necesitas autorización para realizar esta acción'
        );
      });
    } else {
      yield response.json().then(data => {
        throw new Error(data.message || 'Hubo un problema. Vuelva a intentar.');
      });
    }
  } catch (error) {
    yield failureCallback(error, callRequest.params);
    yield put({
      type: utilsTypes.SET_ALERT,
      kind: 'error',
      message: error.message
    });
  }
}

export default runDefaultSaga;
