import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

const objectOrFunction = (component, props) => {
  if (typeof component === 'function') {
    return component(props);
  }
  return component;
};

const DefaultModal = ({
  title,
  body,
  variantBtnClose,
  titleBtnClose,
  variantBtnSave,
  titleBtnSave,
  handleClose,
  handleConfirm,
  closeButton,
  show,
  ...props
}) => (
  <Modal {...props} show={show} onHide={handleClose}>
    <Modal.Header closeButton className={title ? '' : 'border-0 mb-n4'}>
      {title && <Modal.Title className="text-uppercase">{title}</Modal.Title>}
    </Modal.Header>
    <Modal.Body>{objectOrFunction(body)}</Modal.Body>
    <Modal.Footer>
      {titleBtnClose && (
        <Button className="btn btn-light-darker" onClick={handleClose}>
          {titleBtnClose}
        </Button>
      )}
      {titleBtnSave && (
        <Button
          className="btn"
          variant={variantBtnSave}
          onClick={handleConfirm}
        >
          {titleBtnSave}
        </Button>
      )}
    </Modal.Footer>
  </Modal>
);

DefaultModal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  variantBtnClose: PropTypes.string,
  titleBtnClose: PropTypes.string.isRequired,
  variantBtnSave: PropTypes.string,
  titleBtnSave: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
};

DefaultModal.defaultProps = {
  variantBtnClose: 'light-darker',
  variantBtnSave: 'success'
};

export default DefaultModal;
