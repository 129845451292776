import ApiService from '../services/apiService';

export default function showMeRequest({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) {
  ApiService.request('get', `/me`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
}
