import React from 'react';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TooltipBtn = ({ children, variant, to, ...props }) => (
  <OverlayTrigger
    placement="top"
    delay={{ show: 250, hide: 400 }}
    overlay={
      <Tooltip id="tooltip-top">
        Tooltip on <strong>top</strong>.
      </Tooltip>
    }
  >
    <Button as={Link} to={to} {...props} variant={variant}>
      {children}
    </Button>
  </OverlayTrigger>
);

TooltipBtn.propTypes = {
  to: PropTypes.string,
  variant: PropTypes.string
};

TooltipBtn.defaultProps = {
  to: null,
  variant: 'link'
};

export default TooltipBtn;
