import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

const DefaultBtn = ({ children, variant, className, ...props }) => (
  <Button {...props} className={`btn-primary btn-circle btn-xl ${className}`}>
    <div className="btn-title col-12 font-weight-bold text-uppercase">
      {children}
    </div>
  </Button>
);

DefaultBtn.propTypes = {
  to: PropTypes.string,
  variant: PropTypes.string
};

DefaultBtn.defaultProps = {
  to: null,
  variant: 'link'
};

export default DefaultBtn;
