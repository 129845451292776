import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import {
  indexChallengesRequest,
  markChallengesAsReadRequest
} from '../../../requests/challenge';
import ChallengesList from '../ChallengesList';
import { emptyUnreadChallenges } from '../../../actions/utils';
import { Slides, Loader } from '../../../components';
import { indexSlidesRequest } from '../../../requests/slides';

const TriviaIndex = () => {
  const [onRequest, setOnRequest] = useState(true);
  const [challenges, setChallenges] = useState([]);
  const [slides, setSlides] = useState([]);
  const dispatch = useDispatch();
  const {
    settings: { menuType, triviaLinkName }
  } = useSelector(state => state.utils);

  const handleindexChallengesRequest = () => {
    setOnRequest(true);
    indexChallengesRequest({
      dispatch,
      params: {
        challenge_type: 1
      },
      successCallback: result => {
        setChallenges(camelCaseRecursive(result.data.data));
        setOnRequest(false);
      }
    });
  };

  const handleIndexSlidesRequest = () => {
    setOnRequest(true);
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        zone: 5
      },
      successCallback: result => {
        setSlides(camelCaseRecursive(result.data.data));
        setOnRequest(false);
      }
    });
  };

  useEffect(() => {
    handleindexChallengesRequest();
    handleIndexSlidesRequest();

    markChallengesAsReadRequest({
      dispatch
    });

    dispatch(emptyUnreadChallenges());
  }, [dispatch]);

  return onRequest ? (
    <Loader />
  ) : (
    <>
      <Slides items={slides} specific="banner" />
      <h2 id="nn-slides-title" className="screen-title">
        {triviaLinkName || 'Concurso de Acumulación de Puntos'}
      </h2>
      <div className={menuType === 1 ? 'margin-card-products' : ''}>
        <ChallengesList challenges={challenges} trivia />
      </div>
    </>
  );
};

export default withRouter(connect()(TriviaIndex));
