import ApiService from '../services/apiService';

export const showNotificationRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/notifications/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexNotificationsRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/notifications${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};
