import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const showProductRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/products/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexProductsRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/products${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const indexProductCategoriesRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/product_categories${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const debounceIndexProductsRequest = AwesomeDebouncePromise(
  indexProductsRequest,
  300
);
