import ApiService from '../services/apiService';

export const showNewsRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/news/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexNewsRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/news${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const markNewsAsReadRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) => {
  ApiService.request('post', '/news/mark_as_read', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};
