import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { DefaultBtn, FormikInput, MainLogo } from '../../components';
import { sendAlert } from '../../actions/utils';
import { forgotPasswordRequest } from '../../requests/utils';

const RecoverPassword = props => {
  const { onHide, isValid, submitVariant, errors, touched } = props;

  return (
    <Col className="p-0">
      <div className="header" />
      <Row className="p-4 d-flex justify-content-center m-0" md={5}>
        <MainLogo />
      </Row>
      <Row className="justify-content-center align-items-center m-0 full-height">
        <Col md={4}>
          <p className="text-center mt-5">
            Confirma tu correo y enviaremos el link para cambiar contraseña
          </p>
          <Form id="nn-recover-password-form">
            <Field name="email">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="E-mail"
                  placeholder="Email"
                  inputType="email"
                  error={errors[field.name]}
                  touched={touched[field.name]}
                />
              )}
            </Field>
            <DefaultBtn
              variant={submitVariant}
              size="lg"
              className="mt-4"
              block
              type="submit"
              disabled={!isValid}
              onClick={onHide}
            >
              CAMBIAR CONTRASEÑA
            </DefaultBtn>
          </Form>
        </Col>
      </Row>
    </Col>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest } = state.auth;
  return {
    ongoingRequest
  };
};

const initialValues = {
  email: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Este campo es obligatorio')
});

const handleSubmit = (values, { props }) => {
  const { dispatch, history } = props;
  forgotPasswordRequest({
    dispatch,
    params: {
      email: values.email
    },
    successCallback: result => {
      history.push('/login');
      dispatch(sendAlert({ kind: 'success', message: result.data.message }));
    }
  });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(RecoverPassword)
  )
);
