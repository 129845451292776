import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { BackBtn, Slides, Loader } from '../../components';
import { showBenefitRequest } from '../../requests/benefits';

const BenefitShow = ({ match }) => {
  const [onRequest, setOnRequest] = useState(true);
  const [benefit, setBenefit] = useState({});
  const dispatch = useDispatch();

  const {
    settings: { menuType }
  } = useSelector(state => state.utils);

  const handleIndexBenefitRequest = () => {
    setOnRequest(true);
    showBenefitRequest(match.params.id, {
      dispatch,
      successCallback: result => {
        setOnRequest(false);
        setBenefit(camelCaseRecursive(result.data));
      }
    });
  };

  useEffect(handleIndexBenefitRequest, []);

  return onRequest ? (
    <Loader />
  ) : (
    <Row className="justify-content-center">
      <Col md={menuType === 1 ? 10 : 12}>
        <Row>
          <BackBtn
            asLink
            className="mb-2 ml-n1"
            to={{
              pathname: `/category/${benefit.categoryBenefit?.id}`,
              state: [
                benefit.categoryBenefit?.id,
                benefit.categoryBenefit?.name,
                benefit.categoryBenefit?.lede
              ]
            }}
          />
          <Col md={{ span: 12 }}>
            <Slides
              items={[benefit]}
              carouselStyle={{
                maxHeight: 300
              }}
            />
          </Col>
        </Row>
        <Row className="my-4">
          <Col md={12}>
            <h2 className="screen-title">{benefit.title}</h2>
            <p>{benefit.lede}</p>
            <div dangerouslySetInnerHTML={{ __html: benefit.content }} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default BenefitShow;
