import React, { useEffect, useState } from 'react';
import { Badge, Navbar } from 'react-bootstrap';
import { Menu, MenuItem } from 'react-pro-sidebar';
import { BsBellFill } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { indexCategoryBenefitsRequest } from '../requests/categoryBenefits';
import { indexMenuLinksRequest } from '../requests/menuLinks';
import MainLogo from '../components/Utils/MainLogo';

const MenuLinks = ({
  unreadChallenges,
  unreadNews,
  handleToggleSidebar,
  navbar
}) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  const {
    settings: {
      showTriviasLink,
      showContestsLink,
      hideProductsLink,
      showPrizeLink,
      hideProfile,
      termsLink,
      showBenefits,
      prizeNameParam,
      hideRankings,
      benefitNameParam,
      hideNewsLink,
      freeLink,
      freeLinkName,
      newsTitle,
      redirectToProfile,
      challengeWord,
      showContestV2Link,
      triviaLinkName,
      contestsLinkName,
      hideRankingList
    }
  } = useSelector(state => state.utils);

  const handleIndexCategoryRequest = () => {
    indexCategoryBenefitsRequest({
      dispatch,
      params: {
        order_by_position: 'asc'
      },
      successCallback: result => {
        localStorage.setItem(
          'categoryBenefits',
          JSON.stringify(camelCaseRecursive(result.data.data))
        );
      }
    });
  };

  const handleIndexMenuLinksRequest = () => {
    indexMenuLinksRequest({
      dispatch,
      successCallback: result => {
        localStorage.setItem(
          'menuLinks',
          JSON.stringify(camelCaseRecursive(result.data.data))
        );
        setReload(true);
      }
    });
  };

  useEffect(() => {
    handleIndexCategoryRequest();
    handleIndexMenuLinksRequest();
  }, [pathname, reload]);

  const links = JSON.parse(localStorage.getItem('menuLinks'));
  const categoryBenefits = JSON.parse(localStorage.getItem('categoryBenefits'));
  return (
    <Menu iconShape="square" onClick={handleToggleSidebar}>
      <Navbar.Brand className="brand-sidebar">
        <MainLogo header className="nav-logo ml-2" />
      </Navbar.Brand>
      {links?.length > 0 ? (
        <>
          {links?.map(menu => {
            return (
              <MenuItem
                key={menu.id}
                active={pathname.includes(`/${menu.url}`)}
              >
                {menu.name}
                <Link to={{ pathname: `/${menu.url}` }} />
              </MenuItem>
            );
          })}
          {showBenefits === 1 &&
            categoryBenefits?.map(category => {
              return (
                <MenuItem
                  key={category.id}
                  style={{
                    display: category.hasBenefits ? 'block' : 'none'
                  }}
                  active={pathname.includes(`/category/${category.id}`)}
                >
                  {category.name || benefitNameParam || 'Beneficios'}
                  <Link
                    to={{
                      pathname: `/category/${category.id}`,
                      state: [category.id, category.name, category.lede]
                    }}
                  />
                </MenuItem>
              );
            })}
        </>
      ) : (
        <>
          {redirectToProfile === 1 ? (
            <>
              <MenuItem active={pathname.includes('/profile')}>
                Perfil
                <Link to="/profile" />
              </MenuItem>
              <MenuItem active={pathname.includes('/products')}>
                {prizeNameParam || 'Premios'}
                <Link to="/products" />
              </MenuItem>
              <MenuItem
                active={pathname.includes('/challenges')}
                suffix={
                  unreadChallenges > 0 && (
                    <>
                      <BsBellFill style={{ color: '#ffffff' }} />
                      <Badge variant="danger">{unreadChallenges}</Badge>
                    </>
                  )
                }
              >
                {challengeWord || 'Desafíos'}
                <Link to="/challenges" />
              </MenuItem>
              <MenuItem
                active={pathname.includes('/news')}
                suffix={
                  unreadNews > 0 && (
                    <>
                      <BsBellFill style={{ color: '#ffffff' }} />
                      <Badge variant="danger">{unreadNews}</Badge>
                    </>
                  )
                }
              >
                {newsTitle || 'Noticias'}
                <Link to="/news" />
              </MenuItem>
              {freeLink && freeLinkName && (
                <MenuItem active={pathname.includes(freeLink)}>
                  {freeLinkName}
                  <Link to={`/${freeLink}`} />
                </MenuItem>
              )}
              {termsLink && (
                <MenuItem active={pathname.includes(termsLink)}>
                  Bases
                  <Link to={`/${termsLink}`} />
                </MenuItem>
              )}
            </>
          ) : (
            <>
              <MenuItem active={pathname.includes('/home')}>
                Inicio
                <Link to="/home" />
              </MenuItem>
              <MenuItem
                active={pathname.includes('/challenges')}
                suffix={
                  unreadChallenges > 0 && (
                    <>
                      <BsBellFill style={{ color: '#ffffff' }} />
                      <Badge variant="danger">{unreadChallenges}</Badge>
                    </>
                  )
                }
              >
                {challengeWord || 'Desafíos'}
                <Link to="/challenges" />
              </MenuItem>
              {showTriviasLink === 1 && (
                <MenuItem
                  active={pathname.includes('/trivia')}
                  className={navbar ? 'text-ellipsis' : ''}
                >
                  {triviaLinkName || 'Concurso de Acumulación de Puntos'}
                  <Link to="/trivia" />
                </MenuItem>
              )}
              {showContestsLink === 1 && (
                <MenuItem
                  active={pathname.includes('/contest')}
                  className={navbar ? 'text-ellipsis' : ''}
                >
                  {contestsLinkName || 'Concurso Relámpago de Exhibición'}
                  <Link to="/contest" />
                </MenuItem>
              )}
              {showContestV2Link === 1 && (
                <MenuItem active={pathname.includes('/v2_contest')}>
                  Ejecución Perfecta
                  <Link to="/v2_contest" />
                </MenuItem>
              )}
              {hideProductsLink === 1 ? null : (
                <MenuItem active={pathname.includes('/products')}>
                  {prizeNameParam || 'Premios'}
                  <Link to="/products" />
                </MenuItem>
              )}
              {showBenefits === 1 &&
                categoryBenefits?.map(category => {
                  return (
                    <MenuItem
                      key={category.id}
                      style={{
                        display: category.hasBenefits ? 'block' : 'none'
                      }}
                      active={pathname.includes(`/category/${category.id}`)}
                    >
                      {category.name || benefitNameParam || 'Beneficios'}
                      <Link
                        to={{
                          pathname: `/category/${category.id}`,
                          state: [category.id, category.name, category.lede]
                        }}
                      />
                    </MenuItem>
                  );
                })}
              {hideNewsLink === 1 ? null : (
                <MenuItem
                  active={pathname.includes('/news')}
                  suffix={
                    unreadNews > 0 && (
                      <>
                        <BsBellFill style={{ color: '#ffffff' }} />
                        <Badge variant="danger">{unreadNews}</Badge>
                      </>
                    )
                  }
                >
                  {newsTitle || 'Noticias'}
                  <Link to="/news" />
                </MenuItem>
              )}
              {showPrizeLink === 1 && (
                <MenuItem active={pathname.includes('/prize')}>
                  Premios
                  <Link to="/prize" />
                </MenuItem>
              )}
              {hideRankings === 1 || hideRankingList === 1 ? null : (
                <MenuItem active={pathname.includes('/ranking')}>
                  Ranking
                  <Link to="/ranking" />
                </MenuItem>
              )}
              {freeLink && freeLinkName && (
                <MenuItem active={pathname.includes(freeLink)}>
                  {freeLinkName}
                  <Link to={`/${freeLink}`} />
                </MenuItem>
              )}
              {termsLink && (
                <MenuItem active={pathname.includes(termsLink)}>
                  Bases
                  <Link to={`/${termsLink}`} />
                </MenuItem>
              )}
              {hideProfile === 1 ? null : (
                <MenuItem active={pathname.includes('/profile')}>
                  Perfil
                  <Link to="/profile" />
                </MenuItem>
              )}
            </>
          )}
        </>
      )}
    </Menu>
  );
};

export default MenuLinks;
