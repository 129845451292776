import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import camelCaseRecursive from 'camelcase-keys-recursive';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import { indexKpiUsersRequest } from '../../requests/kpiUsers';
import { BasicDataTable, Loader } from '../../components';
import RankingRow from './RankingRow';

import {
  rankingColumns,
  rankingColumnsKpi4,
  zoneColumns,
  zoneColumnsKpi4
} from './columns';

const Ranking = () => {
  const [kpiUsers, setKpiUsers] = useState([]);
  const [kpiZones, setKpiZones] = useState([]);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(
      moment()
        .subtract(30, 'days')
        .locale('en')
        .format('LLL')
    )
  );
  const [endDate, setEndDate] = useState(new Date());
  const dispatch = useDispatch();
  const isSm = useMediaQuery({ query: '(max-width: 550px)' });
  const {
    settings: {
      nameWord,
      kpi4Available,
      hideProductsLink,
      hideZones,
      nameKpi1,
      nameKpi2,
      nameKpi3,
      nameKpi4
    }
  } = useSelector(state => state.utils);
  const firstLetter = nameWord ? ` ${nameWord[0].toLowerCase()}.` : '';

  const nameKpis = [nameKpi1, nameKpi2, nameKpi3, nameKpi4];

  const setRequest = () => {
    indexKpiUsersRequest({
      dispatch,
      params: {
        date_from: startDate,
        date_to: endDate
      },
      successCallback: result => {
        setLoading(false);
        setKpiUsers(camelCaseRecursive(result.data.ranking));
        setKpiZones(camelCaseRecursive(result.data.ranking_zone));
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    setRequest();
  }, [dispatch]);

  useEffect(setRequest, [startDate, endDate]);

  const kpi4 = kpi4Available > 0;
  return (
    <>
      <Row>
        <Col>
          <h2 className="screen-title">Ranking total</h2>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Button
            className={`btn-tabs ${tab === 0 ? 'active-btn' : ''}`}
            onClick={() => setTab(0)}
          >
            General
          </Button>
          {hideZones === 1 ? null : (
            <Button
              className={`btn-tabs ${tab === 1 ? 'active-btn' : ''}`}
              onClick={() => setTab(1)}
            >
              Por zona
            </Button>
          )}
        </Col>
      </Row>
      <Row id="nn-ranking-wrapper" className="mx-1 my-4">
        <Col xs={6} ms={6} md={4}>
          <Form.Group>
            <Form.Label>Fecha inicio</Form.Label>
            <DatePicker
              placeholderText="Desde"
              dateFormat="dd-MM-yyyy"
              className="form-control datepicker-ranking"
              showYearDropdown
              selected={startDate}
              onChange={date => setStartDate(date)}
            />
          </Form.Group>
        </Col>
        <Col xs={6} ms={6} md={4}>
          <Form.Group>
            <Form.Label>Fecha final</Form.Label>
            <DatePicker
              placeholderText="Hasta"
              dateFormat="dd-MM-yyyy"
              className="form-control datepicker-ranking"
              showYearDropdown
              selected={endDate}
              onChange={date => setEndDate(date)}
            />
          </Form.Group>
        </Col>
        <Col xs={12} ms={12} md={12} style={{ padding: 0 }}>
          {loading ? (
            <Loader />
          ) : (
            <>
              {tab === 0 ? (
                <BasicDataTable
                  id="nn-ranking-datatable"
                  columns={
                    kpi4
                      ? rankingColumnsKpi4(
                          isSm,
                          firstLetter,
                          hideZones,
                          nameKpis,
                          hideProductsLink
                        )
                      : rankingColumns(
                          isSm,
                          firstLetter,
                          hideZones,
                          nameKpis,
                          hideProductsLink
                        )
                  }
                  data={kpiUsers}
                  showHeader={false}
                  expandable={isSm}
                  expandableComponent={<RankingRow firstLetter={firstLetter} />}
                />
              ) : (
                <BasicDataTable
                  id="nn-ranking-datatable"
                  columns={
                    kpi4
                      ? zoneColumnsKpi4(
                          isSm,
                          firstLetter,
                          nameKpis,
                          hideProductsLink
                        )
                      : zoneColumns(
                          isSm,
                          firstLetter,
                          nameKpis,
                          hideProductsLink
                        )
                  }
                  data={kpiZones}
                  showHeader={false}
                  expandable={isSm}
                  expandableComponent={<RankingRow firstLetter={firstLetter} />}
                />
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Ranking;
