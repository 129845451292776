import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoize from 'memoize-one';
import moment from 'moment';
import 'moment/locale/es';
import { indexNotificationsRequest } from '../../requests/notifications';
import { RemoteDataTable } from '../../components';

const columns = memoize(() => [
  {
    name: 'Mensaje',
    selector: 'message',
    sortable: true,
    grow: '1'
  },
  {
    name: 'Fecha',
    selector: 'read_at',
    sortable: true,
    grow: '2'
  }
  // {
  //   cell: item => (
  //     <BasicActionBtns
  //       item={item}
  //       clickHandler={clickHandler}
  //       withEdit={false}
  //       withDestroy={false}
  //       withShow={false}
  //     />
  //   ),
  //   ignoreRowClick: true,
  //   allowOverflow: false,
  //   button: true,
  //   grow: '1',
  //   minWidth: '123px'
  // }
]);

const SlideDataTable = props => {
  const [notifications, setNotifications] = useState([]);
  const [onRequest, setOnRequest] = useState(true);
  const [amount, setAmount] = useState(0);

  const dispatch = useDispatch();

  const handleSuccessIndex = response => {
    const _notifications = response.data.data;
    const { amount: _amount } = response.data.metadata;
    setNotifications(_notifications);
    setAmount(_amount);
    setOnRequest(false);
  };

  const handleRequest = params => {
    setOnRequest(true);
    indexNotificationsRequest({
      dispatch,
      params,
      successCallback: handleSuccessIndex
    });
  };

  const handleButtonClick = (item, action) => {
    const { history } = props;
    switch (action) {
      case 'show':
        history.push(`/notifications/${item.id}`);
        break;
      default:
        // eslint-disable-next-line no-alert
        alert('Error: Action not found');
    }
  };

  const sortColumnCase = name => {
    switch (name) {
      case 'caption':
        return { sort_column: name };
      default:
        return { sort_column: name };
    }
  };

  const dataNotifications = () => {
    return notifications.map(notification => {
      return {
        message: notification.message,
        read_at: moment(notification.read_at).format('LL')
      };
    });
  };

  return (
    <>
      <RemoteDataTable
        id="nn-notifications-datatable"
        onRequest={onRequest}
        columns={columns(handleButtonClick)}
        handleSortCase={sortColumnCase}
        data={dataNotifications(notifications)}
        totalRows={amount}
        resourceRequest={handleRequest}
      />
    </>
  );
};

export default withRouter(connect()(SlideDataTable));
