import React from 'react';
import { ProSidebar } from 'react-pro-sidebar';
import { useSelector } from 'react-redux';
import { Image } from 'react-bootstrap';
import MenuLinks from './MenuLinks';
import base from '../assets/stylesheets/base_colors';

const Sidebar = ({
  toggled,
  handleToggleSidebar,
  unreadChallenges,
  unreadNews,
  className
}) => {
  const {
    settings: { bottomSidebarLogo }
  } = useSelector(state => state.utils);
  return (
    <ProSidebar
      onToggle={handleToggleSidebar}
      breakPoint="lg"
      className={`${className} ${base.sidebarStyle}`}
      toggled={toggled}
      width={226}
    >
      <MenuLinks
        unreadChallenges={unreadChallenges}
        unreadNews={unreadNews}
        handleToggleSidebar={handleToggleSidebar}
      />
      {bottomSidebarLogo && (
        <div className="mt-auto">
          <Image
            src={bottomSidebarLogo}
            alt="Logo sidebar inferior"
            className="bottom-logo-sidebar mb-2"
          />
        </div>
      )}
    </ProSidebar>
  );
};

export default Sidebar;
