import React from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './styles.scss';

const LoadSpinner = props => {
  const { position, bottom, top } = props;
  return (
    <div className="containerSpinnerLoad" style={{ position, bottom, top }}>
      <div className="centerview">
        <Spinner {...props} />
      </div>
    </div>
  );
};

LoadSpinner.propTypes = {
  position: PropTypes.string,
  top: PropTypes.number,
  bottom: PropTypes.number
};
LoadSpinner.defaultProps = {
  position: 'absolute',
  top: 0,
  bottom: 0
};

export default LoadSpinner;
