import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const BenefitCard = ({ category, item, categoryId }) => {
  const redirectRoute = () => {
    if (category) return { pathname: '/benefits', state: [item.id, item.name] };

    return `/category/${categoryId}/benefits/${item.id}`;
  };
  return (
    <div
      className={`card border-light benefit-card ${
        category ? 'category' : ''
      } my-3`}
    >
      <img src={item.fileInfo?.fileUrl} className="card-img-top" alt="..." />
      <Link to={redirectRoute()}>
        <h5 className={`card-title ${category ? 'category' : ''}`}>
          {category ? item.name : item.title}
        </h5>
      </Link>
      {!category && (
        <div className="card-description">
          <p className="card-body-text">{item.lede}</p>
        </div>
      )}
    </div>
  );
};

export default BenefitCard;
