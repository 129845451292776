import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl, Button } from 'react-bootstrap';

const InputBtnRight = ({
  placeholder,
  ariaLabel,
  ariaDescribedby,
  variant,
  title
}) => (
  <InputGroup className="mb-3">
    <FormControl
      placeholder={placeholder}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedby}
    />
    <InputGroup.Append>
      <Button variant={variant}>{title}</Button>
    </InputGroup.Append>
  </InputGroup>
);

InputBtnRight.propTypes = {
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  variant: PropTypes.string,
  title: PropTypes.string.isRequired
};

InputBtnRight.defaultProps = {
  placeholder: null,
  ariaLabel: null,
  ariaDescribedby: null,
  variant: 'info'
};

export default InputBtnRight;
