import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import NewsCard from './NewsCard';
import { FullCard } from '../../components';

const NewsList = ({ newsList, newsType, prizeType }) => {
  const {
    settings: { fullCard }
  } = useSelector(state => state.utils);

  return (
    <>
      <Row id="nn-news-wrapper" className="my-4">
        {newsList.map(news => {
          if (fullCard === 1) {
            return (
              <Col key={news.id} xs={12}>
                <FullCard
                  imageURL={news.fileInfo?.fileUrl}
                  title={news.title}
                  subtitle={news.lede}
                  description={news.bodyContent}
                  item={news}
                  newsType={newsType}
                  prizeType={prizeType}
                />
              </Col>
            );
          }
          return (
            <Col xs={6} md={6} lg={3} className="mobile-columns" key={news.id}>
              <NewsCard item={news} newsType={newsType} prizeType={prizeType} />
            </Col>
          );
        })}
      </Row>
    </>
  );
};

NewsList.propTypes = {
  newsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
      bodyContent: PropTypes.text,
      publicationDate: PropTypes.string,
      fileInfo: PropTypes.shape({
        id: PropTypes.number,
        fileUrl: PropTypes.string
      }).isRequired
    })
  ).isRequired
};

export default NewsList;
