export const utilsTypes = {
  // UNREAD CHALLENGES
  EMPTY_UNREAD_CHALLENGES: 'EMPTY_UNREAD_CHALLENGES',
  GET_UNREAD_CHALLENGES_REQUEST: 'GET_UNREAD_CHALLENGES_REQUEST',
  GET_UNREAD_CHALLENGES_SUCCESS: 'GET_UNREAD_CHALLENGES_SUCCESS',
  GET_UNREAD_CHALLENGES_FAILURE: 'GET_UNREAD_CHALLENGES_FAILURE',
  // UNREAD NEWS
  EMPTY_UNREAD_NEWS: 'EMPTY_UNREAD_NEWS',
  GET_UNREAD_NEWS_REQUEST: 'GET_UNREAD_NEWS_REQUEST',
  GET_UNREAD_NEWS_SUCCESS: 'GET_UNREAD_NEWS_SUCCESS',
  GET_UNREAD_NEWS_FAILURE: 'GET_UNREAD_NEWS_FAILURE',
  // SETTINGS
  GET_SETTINGS_REQUEST: 'GET_SETTINGS_REQUEST',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',
  // SLIDES
  GET_SLIDES_REQUEST: 'GET_SLIDES_REQUEST',
  GET_SLIDES_SUCCESS: 'GET_SLIDES_SUCCESS',
  GET_SLIDES_FAILURE: 'GET_SLIDES_FAILURE',
  // PAGE
  GET_PAGE_REQUEST: 'GET_PAGE_REQUEST',
  GET_PAGE_SUCCESS: 'GET_PAGE_SUCCESS',
  GET_PAGE_FAILURE: 'GET_PAGE_FAILURE',
  // ALERT
  SET_ALERT: 'SET_ALERT',
  CLOSE_ALERT: 'CLOSE_ALERT',
  SET_ALERT_ERROR: 'SET_ALERT_ERROR',
  // SET_HEADERS
  SET_HEADERS: 'SET_HEADERS',

  GET_UNREAD_NOTIFICATIONS_REQUEST: 'GET_UNREAD_NOTIFICATIONS_REQUEST',
  GET_UNREAD_NOTIFICATIONS_SUCCESS: 'GET_UNREAD_NOTIFICATIONS_SUCCESS',
  GET_UNREAD_NOTIFICATIONS_FAILURE: 'GET_UNREAD_NOTIFICATIONS_FAILURE',

  GET_LAST_NOTIFICATIONS_REQUEST: 'GET_LAST_NOTIFICATIONS_REQUEST',
  GET_LAST_NOTIFICATIONS_SUCCESS: 'GET_LAST_NOTIFICATIONS_SUCCESS',
  GET_LAST_NOTIFICATIONS_FAILURE: 'GET_LAST_NOTIFICATIONS_FAILURE',

  SET_NOTIFICATIONS_READ_REQUEST: 'SET_NOTIFICATIONS_READ_REQUEST',
  SET_NOTIFICATIONS_READ_SUCCESS: 'SET_NOTIFICATIONS_READ_SUCCESS',
  SET_NOTIFICATIONS_READ_FAILURE: 'SET_NOTIFICATIONS_READ_FAILURE'
};

// UNREAD CHALLENGES
export const emptyUnreadChallenges = () => ({
  type: utilsTypes.EMPTY_UNREAD_CHALLENGES
});

export const getUnreadChallenges = () => ({
  type: utilsTypes.GET_UNREAD_CHALLENGES_REQUEST
});

// UNREAD NEWS
export const emptyUnreadNews = () => ({
  type: utilsTypes.EMPTY_UNREAD_NEWS
});

export const getUnreadNews = () => ({
  type: utilsTypes.GET_UNREAD_NEWS_REQUEST
});

// SETTINGS
export const getSettings = () => ({ type: utilsTypes.GET_SETTINGS_REQUEST });

// SLIDE
export const getSlides = () => ({ type: utilsTypes.GET_SLIDES_REQUEST });

// PAGE
export const getPage = url => ({
  type: utilsTypes.GET_PAGE_REQUEST,
  url
});

// ALERT
export const closeAlert = params => ({
  type: utilsTypes.CLOSE_ALERT,
  params
});
export const sendAlert = params => ({
  type: utilsTypes.SET_ALERT,
  ...params
});

export const setHeaders = params => ({
  type: utilsTypes.SET_HEADERS,
  ...params
});

export const getLastNotifications = () => ({
  type: utilsTypes.GET_LAST_NOTIFICATIONS_REQUEST
});

export const setNotificationsRead = () => ({
  type: utilsTypes.SET_NOTIFICATIONS_READ_REQUEST
});

export const getUnreadNotifications = () => ({
  type: utilsTypes.GET_UNREAD_NOTIFICATIONS_REQUEST
});
