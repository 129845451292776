const primary = '#0177C8';
const secondary = '#EB0248';
const white = '#ffffff';

const baseColors = {
  primary,
  secondary,
  red: '#dc3545',
  primaryBg: white,

  // text color login (className)
  textColorLogin: 'text-white',

  // datatable
  bgDatatable: white,
  colorDatatable: 'null',

  // style logo login
  styleLoginLogo: {
    marginTop: '5%',
    maxWidth: 280,
    minWidth: 265
  },

  logoHeight: 320,

  // select
  bgSelect: white,
  bgMultiValueSelect: secondary,
  hoverBgMultiValueRemoveSelect: secondary,
  colorMultiValueSelect: white,
  hoverTextMultiValueRemoveSelect: white,

  // text points product
  colorTextPointsProducts: primary,

  gray100: '#f8f9fa',
  gray200: '#e9ecef',
  gray300: '#dee2e6',
  gray400: '#ced4da',
  gray500: '#adb5bd',
  gray600: '#6c757d',
  gray700: '#495057',
  gray800: '#343a40',
  gray900: '#282828'
};

export default baseColors;
