import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl } from 'react-bootstrap';

const InputCheckbox = ({ size, ariaLabelInp, ariaLabelForm }) => (
  <InputGroup size={size} className="mb-3">
    <InputGroup.Prepend>
      <InputGroup.Checkbox aria-label={ariaLabelInp} />
    </InputGroup.Prepend>
    <FormControl aria-label={ariaLabelForm} />
  </InputGroup>
);

InputCheckbox.propTypes = {
  size: PropTypes.string,
  ariaLabelInp: PropTypes.string
};

InputCheckbox.defaultProps = {
  size: 'sm',
  ariaLabelInp: null
};

export default InputCheckbox;
