import React from 'react';
import { Link } from 'react-router-dom';
// import { BsChevronCompactDown } from 'react-icons/bs';
import { CircleProgress } from '../../components';
import ChallengeCard from './ChallengeCard';
import baseColors from '../../assets/stylesheets/base_colors';

const ChallengeLayout = ({ type, challenge }) => {
  const percentage = challenge.myAdvanceChallenge?.fulfillment;

  const barColor = () => {
    if (percentage <= 20) return 'red';
    if (percentage > 20 && percentage < 60) return '#FFBE1E';

    return '#6DC648';
  };

  if (challenge?.format === 'default') {
    return <ChallengeCard item={challenge} />;
  }

  if (challenge?.highlight) {
    return (
      <Link className="not-underline" to={`/challenges/${challenge.id}`}>
        <div className="card-highlight mt-2">
          <div
            className="title-box"
            style={{ backgroundColor: baseColors.primary }}
          >
            <p className="title">{challenge.title}</p>
          </div>
          {challenge?.format === 'progress' && (
            <div className="mt-5 mb-3">
              <CircleProgress color={barColor()} percentage={percentage || 0} />
            </div>
          )}
          {challenge?.format === 'ranking' && (
            <div className="mt-5 mb-3">
              <div className="circle mt-1">
                {challenge.iconUrl && (
                  <img src={challenge.iconUrl} alt="icon-challenge" />
                )}
                <div className="d-flex flex-column align-items-center">
                  <p className="number">
                    {challenge.myAdvanceChallenge?.advance || 0}°
                  </p>
                  <p>Lugar</p>
                </div>
              </div>
            </div>
          )}
          {challenge?.format === 'accumulation' && (
            <div className="mt-5 mb-3">
              <div className="circle mt-1">
                {challenge.iconUrl && (
                  <img src={challenge.iconUrl} alt="icon-challenge" />
                )}
                <div className="d-flex flex-column align-items-center">
                  <p className="number">
                    {challenge.myAdvanceChallenge?.accumulatedPoints || 0}
                  </p>
                  <p>Puntos</p>
                </div>
              </div>
            </div>
          )}
          <div className="mt-4">
            {challenge.myAdvanceChallenge?.text1 !== '' && (
              <p className="mb-1 text-center text-info text-primary font-bold">
                {challenge.myAdvanceChallenge?.text1}
              </p>
            )}
            {challenge.myAdvanceChallenge?.text2 !== '' && (
              <p className="mb-2 text-center text-info text-gray font-bold">
                {challenge.myAdvanceChallenge?.text2}
              </p>
            )}
          </div>
          {/* <Link
            className="mt-auto mb-4 link"
            to={`/challenges/${challenge.id}`}
          >
            <BsChevronCompactDown
              size={25}
              style={{ color: baseColors.primary }}
            />{' '}
            Ver más
          </Link> */}
        </div>
      </Link>
    );
  }

  if (type === 'ranking') {
    return (
      <Link className="not-underline" to={`/challenges/${challenge.id}`}>
        <div
          className="card-challenge-list mb-4"
          style={{ border: `1.5px solid ${baseColors.primary}` }}
        >
          <div className="d-flex align-items-center w-100">
            <div className="img-list mr-2 mr-xl-4">
              {challenge.iconUrl && (
                <img src={challenge.iconUrl} alt="icon-ranking" />
              )}
            </div>
            <div className="w-100 pt-2">
              <p
                className="text-primary font-bold text-size title"
                style={{ maxWidth: '85%' }}
              >
                {challenge?.title}
              </p>
              <div className="d-flex justify-content-xl-between align-items-center">
                <div
                  className="box-ranking"
                  style={{ backgroundColor: baseColors.primary }}
                >
                  <p className="number">
                    {challenge.myAdvanceChallenge?.advance || 0}°
                  </p>
                  <p className="ml-2">Lugar</p>
                </div>
              </div>
              <div className="d-flex mt-3 flex-wrap box-info">
                <p className="text-gray mb-1 mr-1 mr-md-0">
                  Avance:{' '}
                  <span className="font-bold">
                    {challenge?.symbolType}
                    {challenge.myAdvanceChallenge?.advance || 0}{' '}
                  </span>
                  <span className="mx-2 d-none d-md-inline-block">|</span>
                </p>
                <p className="text-gray">
                  Distancia 1° Lugar:{' '}
                  <span className="font-bold">
                    {challenge?.symbolType}
                    {challenge.myAdvanceChallenge?.remaining || 0}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }

  if (type === 'progress') {
    return (
      <Link className="not-underline" to={`/challenges/${challenge.id}`}>
        <div
          className="card-challenge-list mb-4"
          style={{ border: `1.5px solid ${baseColors.primary}` }}
        >
          <div className="d-flex align-items-center w-100">
            <div className="img-list mr-2 mr-xl-4">
              {challenge.iconUrl && (
                <img src={challenge.iconUrl} alt="icon-progress" />
              )}
            </div>
            <div className="pt-2 box-progress">
              <p
                className="text-primary font-bold text-size title title-progress mb-2"
                style={{ maxWidth: '65%' }}
              >
                {challenge?.title}
              </p>
              <div className="d-flex justify-content-xl-between align-items-center">
                <p className="text-ellipsis text-summary w-100 mb-1">
                  {challenge.summary}
                </p>
              </div>
              <div className="mt-2">
                <div className="progress-bar-list">
                  <div
                    className="progress-list"
                    style={{
                      backgroundColor: barColor(),
                      width: `${percentage || 0}%`
                    }}
                  >
                    <p
                      style={
                        percentage <= 15
                          ? { position: 'relative', left: 30 }
                          : {}
                      }
                      className={`m-0 pl-2 pr-1 text-percentage ${
                        percentage > 0 ? '' : 'd-none'
                      }`}
                    >
                      {percentage || 0}%
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-2 d-flex justify-content-between box-info mb-1">
                <p className="m-0 font-bold">
                  {challenge?.symbolType}
                  {challenge.myAdvanceChallenge?.advance || 0}
                </p>
                <p className="m-0 font-bold">
                  {challenge?.symbolType}
                  {challenge.myAdvanceChallenge?.goal || 0}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }

  if (type === 'accumulation') {
    return (
      <Link className="not-underline" to={`/challenges/${challenge.id}`}>
        <div
          className="card-challenge-list mb-4"
          style={{ border: `1.5px solid ${baseColors.primary}` }}
        >
          <div className="d-flex align-items-center w-100">
            <div className="img-list mr-2 mr-xl-4">
              {challenge.iconUrl && (
                <img src={challenge.iconUrl} alt="icon-accumulation" />
              )}
            </div>
            <div className="w-100 pt-2">
              <p
                className="text-primary font-bold text-size title title-accumulation"
                style={{ maxWidth: '85%' }}
              >
                {challenge?.title}
              </p>
              <div className="d-flex justify-content-xl-between align-items-center">
                <div
                  className="box-ranking"
                  style={{ backgroundColor: baseColors.primary }}
                >
                  <p className="number">
                    {challenge.myAdvanceChallenge?.accumulatedPoints || 0}
                  </p>
                  <p className="ml-2">Puntos</p>
                </div>
              </div>
              <div className="d-flex mt-3 box-info">
                <p className="text-gray">
                  Comprado:{' '}
                  <span className="font-bold">
                    {challenge?.symbolType}
                    {challenge.myAdvanceChallenge?.advance || 0}{' '}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  }

  return <></>;
};

export default ChallengeLayout;
