import ApiService from '../services/apiService';

export const indexCategoryBenefitsRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) => {
  ApiService.request('get', '/category_benefits', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};
