import React, { useEffect } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPage } from '../../actions/utils';

const Page = props => {
  const dispatch = useDispatch();
  const { match } = props;

  useEffect(() => {
    dispatch(getPage(match.params.page));
  }, [match?.url]);

  const loading = () => (
    <div style={{ marginTop: '10%' }}>
      <Spinner animation="border" variant="primary" />
    </div>
  );

  const content = () => {
    const {
      utils: {
        page: { body }
      }
    } = props;

    if (body) {
      return <div dangerouslySetInnerHTML={{ __html: body }} />;
    }
    return (
      <div className="d-flex justify-content-center">
        <h3 className="mt-4">404</h3>
      </div>
    );
  };

  const {
    utils: { ongoingRequest }
  } = props;
  return (
    <Row className="justify-content-center">
      {ongoingRequest.getPage ? loading() : <Col md={10}>{content()}</Col>}
    </Row>
  );
};

const mapStateToProps = state => {
  const { utils, auth } = state;
  return {
    utils,
    auth
  };
};

export default withRouter(connect(mapStateToProps)(Page));
