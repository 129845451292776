import React from 'react';
import baseColors from '../../../assets/stylesheets/base_colors';

const CircleProgress = ({ percentage, color }) => {
  const radius = 90;
  const circumference = 2 * Math.PI * radius;
  const dashOffset = circumference - (percentage / 100) * circumference;

  return (
    <div className="circle-progress">
      <svg className="svg" width={radius * 2 + 20} height={radius * 2 + 20}>
        <circle
          className="circle-bg"
          strokeWidth="12"
          fill="transparent"
          r={radius}
          cx={radius + 10}
          cy={radius + 10}
        />
        <circle
          className="circle"
          strokeWidth="12"
          fill="transparent"
          r={radius}
          cx={radius + 10}
          cy={radius + 10}
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: dashOffset,
            stroke: color
          }}
        />
      </svg>
      <div className="percentage" style={{ color: baseColors.primary }}>
        <p>{percentage}%</p>
      </div>
    </div>
  );
};

export default CircleProgress;
