import React from 'react';
import AsyncSelect from 'react-select/async';

const RemoteSelect = ({ disabled, value, request, onChange, ...moreProps }) => (
  <AsyncSelect
    placeholder="Buscar..."
    loadingMessage={() => 'Cargando...'}
    noOptionsMessage={() => 'No hay opciones'}
    value={value}
    defaultOptions
    loadOptions={request}
    onChange={onChange}
    isDisabled={disabled}
    {...moreProps}
  />
);

export default RemoteSelect;
