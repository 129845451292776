import React from 'react';
import { useSelector } from 'react-redux';
import { Tab, Nav, Row, Col } from 'react-bootstrap';
import RedeemsDataTable from '../Redeem/RedeemsDataTable';
import TransactionDataTable from '../Transaction/TransactionsDataTable';

const PointsIndex = () => {
  const {
    settings: { nameWord }
  } = useSelector(state => state.utils);
  return (
    <>
      <Row>
        <Col>
          <h2>Mis {nameWord}</h2>
        </Col>
      </Row>

      <Row id="nn-transactions-redeems-wrapper">
        <Col>
          <Tab.Container defaultActiveKey="tab-transactions">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="tab-transactions">
                  Historial de transacciones
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tab-redeems">Detalle</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="tab-transactions">
                <TransactionDataTable />
              </Tab.Pane>
              <Tab.Pane eventKey="tab-redeems">
                <RedeemsDataTable trakingLink={false} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </>
  );
};

export default PointsIndex;
