import React, { useState } from 'react';
import { Tab, Nav, Row, Col, ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DefaultBtn, Slides } from '../../components';
import { formatToPoints } from '../../services/utils';
import RenderCkeditor from './RenderCkeditor';
import baseColors from '../../assets/stylesheets/base_colors';
import './styles.scss';

const ChallengeInfo = props => {
  const { challenge } = props;
  const [collapsed, setCollapsed] = useState(true);
  const {
    settings: { nameWord, hideWinners }
  } = useSelector(state => state.utils);

  const percentage = challenge.myAdvanceChallenge?.fulfillment;

  const barColor = () => {
    if (percentage <= 20) return 'red';
    if (percentage > 20 && percentage < 60) return '#FFBE1E';

    return '#6DC648';
  };

  const listWinners = () => {
    return challenge.winners.map(winner => {
      return <ListGroup.Item>{winner.fullName}</ListGroup.Item>;
    });
  };

  const renderType = () => {
    if (challenge.format === 'progress') {
      return (
        <>
          <div className="mt-2 mt-xl-4">
            <div className="progress-bar-list">
              <div
                className="progress-list"
                style={{
                  backgroundColor: barColor(),
                  width: `${percentage || 0}%`
                }}
              >
                <p
                  style={
                    percentage <= 15 ? { position: 'relative', left: 30 } : {}
                  }
                  className={`m-0 pl-2 pr-1 text-percentage ${
                    percentage > 0 ? '' : 'd-none'
                  }`}
                >
                  {percentage || 0}%
                </p>
              </div>
            </div>
          </div>
          <div className="mt-2 d-flex justify-content-between box-info mb-1">
            <p className="m-0 font-bold">
              {challenge?.symbolType}
              {challenge.myAdvanceChallenge?.advance || 0}
            </p>
            <p className="m-0 font-bold">
              {challenge?.symbolType}
              {challenge.myAdvanceChallenge?.goal || 0}
            </p>
          </div>
        </>
      );
    }

    if (challenge.format === 'ranking') {
      return (
        <div className="challenge-show">
          <div className="d-flex align-items-center w-100">
            <div className="w-100 pt-2">
              <div className="d-flex justify-content-center align-items-center">
                {challenge.iconUrl && (
                  <div className="img-list mr-2 mr-xl-4">
                    <img src={challenge.iconUrl} alt="icon-ranking" />
                  </div>
                )}
                <div
                  className="box-ranking"
                  style={{ backgroundColor: baseColors.primary }}
                >
                  <p className="number">
                    {challenge.myAdvanceChallenge?.advance || 0}°
                  </p>
                  <p className="ml-2">Lugar</p>
                </div>
              </div>
              <div className="d-flex mt-3 flex-wrap justify-content-around box-info">
                {challenge.myAdvanceChallenge?.text1 !== '' && (
                  <p className="text-primary mb-0 font-bold">
                    {challenge.myAdvanceChallenge?.text1}
                  </p>
                )}
                {challenge.myAdvanceChallenge?.text2 !== '' && (
                  <p className="text-gray m-0 font-bold">
                    {challenge.myAdvanceChallenge?.text2}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (challenge.format === 'accumulation') {
      return (
        <div className="d-flex justify-content-center challenge-show">
          <div className="d-flex align-items-center">
            {challenge.iconUrl && (
              <div className="img-list mr-2 mr-xl-4">
                <img src={challenge.iconUrl} alt="icon-accumulation" />
              </div>
            )}
            <div className="w-100 pt-2">
              <div className="d-flex justify-content-xl-between align-items-center">
                <div
                  className="box-ranking"
                  style={{ backgroundColor: baseColors.primary }}
                >
                  <p className="number">
                    {challenge.myAdvanceChallenge?.goal || 0}
                  </p>
                  <p className="ml-2">Puntos</p>
                </div>
              </div>
              <div className="d-flex flex-column mt-3 box-info">
                {challenge.myAdvanceChallenge?.text1 !== '' && (
                  <p className="text-primary mb-0 font-bold">
                    {challenge.myAdvanceChallenge?.text1}
                  </p>
                )}
                {challenge.myAdvanceChallenge?.text2 !== '' && (
                  <p className="text-gray m-0">
                    {challenge.myAdvanceChallenge?.text2}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <></>;
  };

  return (
    <>
      {challenge.imageUrl && (
        <Row>
          <Col md={{ span: 7 }} xs={{ span: 12 }}>
            <Row className="mb-3">
              <Col>
                <Slides
                  items={[{ fileInfo: { fileUrl: challenge.imageUrl } }]}
                  classNameImg="show-preview"
                  specific="show-carousel"
                />
              </Col>
            </Row>
          </Col>

          <Col className="mb-4" md={{ order: 2 }} xs={{ span: 12, order: 1 }}>
            <h2 className="title-show">{challenge.title}</h2>
            <p className="mt-3 description-show">{challenge.summary}</p>
            {challenge.format === 'default' ? (
              <>
                <div className="d-flex flex-wrap">
                  <div className="mr-2 my-auto nn-circle" />
                  <p
                    className="my-auto reward-text"
                    style={{ color: baseColors.colorTextPointsProducts }}
                  >
                    {formatToPoints(challenge.reward)} {nameWord}
                  </p>
                </div>
                {hideWinners === 1 ? (
                  <>
                    <p className="mt-3">
                      La fecha límite para realizar el desafío es:
                    </p>
                    <DefaultBtn className="type-btn">
                      {challenge.dateText
                        ? challenge.dateText
                        : challenge.endDate.split('00:00')[0]}
                    </DefaultBtn>
                  </>
                ) : (
                  <>
                    <p className="mt-3">Ha sido completado por:</p>
                    <DefaultBtn onClick={() => setCollapsed(!collapsed)}>
                      {challenge.winners.length} personas
                    </DefaultBtn>
                  </>
                )}
                <ListGroup
                  className={`${collapsed ? 'nn-collapsed' : ''}`}
                  variant="flush"
                >
                  {listWinners()}
                </ListGroup>
              </>
            ) : (
              renderType()
            )}
          </Col>
        </Row>
      )}
      <div className="challange-info-container-tab">
        <Tab.Container
          className="tab-container"
          defaultActiveKey="tab-specifications"
        >
          <Nav variant="tabs">
            <Nav.Item className="tab-label">
              <Nav.Link eventKey="tab-specifications" className="tab-link">
                Descripción
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="tab-label">
              <Nav.Link eventKey="tab-dispatch" className="tab-link">
                Términos y Condiciones
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="tab-specifications">
              <RenderCkeditor
                className="mt-2 tab-content"
                text={challenge.description}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="tab-dispatch" className="py-1 px-2">
              <RenderCkeditor
                className="mt-2 tab-content"
                text={challenge.termsAndConditions}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default ChallengeInfo;
