/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { BsPersonCircle } from 'react-icons/bs';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Link, useLocation } from 'react-router-dom';
import { indexMenuLinksRequest } from '../../../requests/menuLinks';
import { requestSignOut } from '../../../actions/auth';

const ProfileDropdown = ({ loggedIn }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    settings: { nameWord, hideProfile }
  } = useSelector(state => state.utils);

  const handleIndexMenuLinksRequest = () => {
    indexMenuLinksRequest({
      dispatch,
      successCallback: result => {
        localStorage.setItem(
          'menuLinks',
          JSON.stringify(camelCaseRecursive(result.data.data))
        );
      }
    });
  };

  useEffect(() => {
    handleIndexMenuLinksRequest();
  }, [pathname]);

  const links = JSON.parse(localStorage.getItem('menuLinks'));
  const profileName = links?.find(i => i.urlSelector === 'profile')?.name;
  return (
    <Dropdown alignRight className="position-dropdown">
      <Dropdown.Toggle variant="circle-dark" id="dropdown-basic">
        <BsPersonCircle style={{ color: '#ffffff' }} />
      </Dropdown.Toggle>
      <Dropdown.Menu className="t-r-0 mobile">
        {loggedIn ? (
          <>
            {hideProfile === 1 ? null : (
              <>
                {links?.length > 0 && profileName && (
                  <>
                    <div className="profile-dropdown-link">
                      <Link className="ml-3" to="/profile">
                        {profileName}
                      </Link>
                    </div>
                    <br />
                  </>
                )}
                {links?.length === 0 && (
                  <>
                    <div className="profile-dropdown-link">
                      <Link className="ml-3" to="/profile">
                        Perfil
                      </Link>
                    </div>
                    <br />
                  </>
                )}
              </>
            )}
            <div className="profile-dropdown-link">
              <Link className="ml-3" to="/points-summary">
                Mis {nameWord}
              </Link>
            </div>
            <br />
            <div className="profile-dropdown-link">
              <Link className="ml-3" to="/change_password">
                Cambiar contraseña
              </Link>
            </div>
            <br />
            <div className="profile-dropdown-link">
              <Link
                className="ml-3"
                to="#"
                onClick={() => dispatch(requestSignOut())}
              >
                Cerrar sesión
              </Link>
            </div>
          </>
        ) : (
          <>
            <Link className="ml-3" to="/login">
              Iniciar sesión
            </Link>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default connect()(ProfileDropdown);
