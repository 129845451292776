import ApiService from '../services/apiService';

export const showChallengeRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request('get', `/challenges/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexChallengesRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/challenges${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const markChallengesAsReadRequest = ({
  dispatch,
  params = {},
  failureCallback,
  successCallback
}) => {
  ApiService.request('post', '/challenges/mark_as_read', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};
