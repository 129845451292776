import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Dropdown, Badge, Row, Col, Spinner } from 'react-bootstrap';
import { BsBellFill } from 'react-icons/bs';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { useHistory, Link } from 'react-router-dom';
import {
  getUnreadNotifications,
  getLastNotifications,
  setNotificationsRead
} from '../../../actions/utils';

const NotificationsDropdown = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const {
    unreadNotifications,
    navbarNotifications,
    ongoingRequest: { getNotification }
  } = useSelector(state => state.utils);

  useEffect(() => {
    dispatch(getUnreadNotifications());
  }, [dispatch, navbarNotifications]);

  useEffect(() => {
    if (
      navbarNotifications.length > 0 &&
      navbarNotifications.length !== notifications.length
    ) {
      setNotifications(navbarNotifications);
      dispatch(setNotificationsRead());
    }
  }, [dispatch, notifications.length, navbarNotifications]);

  const loadLastNotifications = () => {
    dispatch(getLastNotifications());
  };

  const redirectNotification = noti => {
    switch (noti.notificationType) {
      case 'news':
        history.push(`/news/${noti.newsId}`);
        break;
      case 'challenges':
        history.push(`/challenges/${noti.challengeId}`);
        break;
      default:
        history.push(`/notifications`);
        break;
    }
  };

  return (
    <Dropdown
      alignRight
      className="position-dropdown mr-n3"
      onClick={() => loadLastNotifications()}
    >
      <Dropdown.Toggle variant="circle-dark" id="dropdown-basic">
        <BsBellFill style={{ color: '#ffffff' }} />
        {unreadNotifications > 0 && (
          <Badge variant="danger">{unreadNotifications}</Badge>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="notifications t-r-0 mobile">
        <>
          {getNotification ? (
            <div className="notifications-loader">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="notifications-scrollbar">
              {notifications.map((notification, index) => (
                <Dropdown.Item
                  onClick={() => redirectNotification(notification)}
                  key={`notificationTop${index.toString()}`}
                >
                  {notification.readAt ? (
                    <span className="dot dot-grey" />
                  ) : (
                    <span className="dot dot-danger" />
                  )}
                  <>
                    <Row>
                      <Col xs={2} className="notification-container-icon">
                        <HiOutlineUserCircle
                          style={{ fontSize: 28, color: '#0e1d61' }}
                        />
                      </Col>
                      <Col xs={10}>
                        <p className="notification-message mb-0">
                          {notification.message}
                        </p>
                      </Col>
                      <Col xs={{ span: 10, offset: 2 }}>
                        <p className="text-muted time-notification">
                          {notification.timeAgo}
                        </p>
                      </Col>
                    </Row>
                  </>
                </Dropdown.Item>
              ))}
            </div>
          )}
          {notifications.length === 0 && !getNotification && (
            <small className="ml-3 mt-3">
              <Link to="/notifications">No tienes ninguna notificación</Link>
            </small>
          )}
        </>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default connect()(NotificationsDropdown);
