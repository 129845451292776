import React, { useState, useEffect } from 'react';
import { Col, FormControl, Form, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import memoize from 'memoize-one';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import PropTypes from 'prop-types';
import baseColors from '../../../assets/stylesheets/base_colors';
// https://www.npmjs.com/package/react-data-table-component

const RemoteDataTable = ({
  moreData,
  RowsPage,
  handleSortCase,
  resourceRequest,
  rangePicker,
  withSearching,
  ...props
}) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [textInput, setTextInput] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPage, setRowsPage] = useState(RowsPage);
  const [column, setColumn] = useState('created_at');
  const [direction, setDirection] = useState('desc');
  const debounceRequest = AwesomeDebouncePromise(resourceRequest, 500);

  const setRequest = params => {
    let sortColumn = { sort_column: column };
    let dateFilter = { date_from: startDate, date_to: endDate };
    if (rangePicker && startDate && endDate) {
      dateFilter = { date_range: [startDate, endDate] };
    }
    if (handleSortCase) {
      sortColumn = handleSortCase(column);
    }
    debounceRequest({
      query: textInput,
      display_start: page * rowsPage,
      display_length: rowsPage,
      sort_direction: direction,
      ...dateFilter,
      ...sortColumn,
      ...moreData,
      ...params
    });
  };

  const onChangePage = pageValue => {
    setPage(pageValue - 1);
    setRequest({ display_start: (pageValue - 1) * rowsPage });
  };

  const onChangeRowsPage = RowsPageValue => {
    setRowsPage(RowsPageValue);
    setRequest({ display_start: 0, display_length: RowsPageValue });
  };

  const handleSort = (colValue, dirValue) => {
    setDirection(dirValue);
    setPage(0);
    setColumn(colValue.selector);
    setRequest({
      display_start: 0,
      sort_direction: dirValue
    });
  };

  const handleRowSelection = (selection, handleSelect) => {
    if (!handleSelect) {
      return;
    }
    handleSelect(selection.selectedRows);
  };

  useEffect(setRequest, [moreData, startDate, endDate, textInput]);
  const {
    onRequest,
    data,
    totalRows,
    withStartDate,
    withEndDate,
    handleSelect,
    showPagination
  } = props;

  return (
    <div className="mt-4 datatable" style={{ position: 'relative' }}>
      {onRequest && (
        <div
          className="containerSpinnerLoad"
          style={{ position: 'absolute', height: '100%' }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <DataTable
        className="custom-data-table"
        pagination={showPagination}
        noDataComponent={onRequest ? '' : 'No se encontraron datos.'}
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={rowsPage}
        paginationRowsPerPageOptions={[5, 10, 15]}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPage}
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por pagina:',
          rangeSeparatorText: 'de'
        }}
        sortServer
        onRowSelected={memoize(selection =>
          handleRowSelection(selection, handleSelect)
        )}
        onSort={(colValue, dirValue) => handleSort(colValue, dirValue)}
        sortFunction={a => a}
        noHeader
        subHeader={withStartDate || withEndDate || withSearching}
        disabled={onRequest}
        data={data}
        customStyles={{
          headCells: {
            style: {
              border: 'none',
              fontWeight: 'bold !important',
              fontSize: '16px',
              backgroundColor: baseColors.bgDatatable,
            color: baseColors.colorDatatable
            }
          },
          cells: {
            style: {
              border: 'none',
              fontSize: '16px',
              backgroundColor: baseColors.bgDatatable,
              color: baseColors.colorDatatable
            }
          }
        }}
        subHeaderComponent={
          <>
            {withStartDate && (
              <Col xs={2}>
                <Form.Group>
                  <DatePicker
                    placeholderText="Desde"
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    showYearDropdown
                    selected={startDate}
                    onChange={date => {
                      setStartDate(date);
                      setPage(0);
                    }}
                  />
                </Form.Group>
              </Col>
            )}
            {withEndDate && (
              <Col xs={2}>
                <Form.Group>
                  <DatePicker
                    placeholderText="Hasta"
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    showYearDropdown
                    selected={endDate}
                    onChange={date => {
                      setEndDate(date);
                      setPage(0);
                    }}
                  />
                </Form.Group>
              </Col>
            )}
            {withSearching && (
              <Col md={3}>
                <Form.Group>
                  <FormControl
                    placeholder="Buscar"
                    name="textInput"
                    value={textInput}
                    onChange={e => {
                      setPage(0);
                      setTextInput(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            )}
          </>
        }
        {...props}
      />
    </div>
  );
};

RemoteDataTable.propTypes = {
  onRequest: PropTypes.bool,
  totalRows: PropTypes.number,
  RowsPage: PropTypes.number,
  moreData: PropTypes.bool,
  withSearching: PropTypes.bool,
  resourceRequest: PropTypes.func,
  showPagination: PropTypes.bool
};

RemoteDataTable.defaultProps = {
  onRequest: false,
  totalRows: 0,
  RowsPage: 15,
  moreData: false,
  withSearching: true,
  resourceRequest: () => null,
  showPagination: true
};

export default RemoteDataTable;
