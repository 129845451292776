import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import { ChevronLeft } from 'react-feather';
import PropTypes from 'prop-types';

const BackBtn = ({ route, asLink, to, ...props }) => {
  const history = useHistory();
  const goRoute = () => history.push(route);

  return (
    <>
      <Button
        as={asLink && Link}
        to={asLink && to}
        onClick={goRoute}
        variant="link"
        size="sm"
        {...props}
      >
        <ChevronLeft size={32} />
      </Button>
    </>
  );
};

BackBtn.propTypes = {
  route: PropTypes.string
};

BackBtn.defaultProps = {
  route: '/'
};
export default BackBtn;
