import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Button } from 'react-bootstrap';
import baseColors from '../../assets/stylesheets/base_colors';

export default class RankingChart extends PureComponent {
  setNextMonth = () => {
    const { nextMonth } = this.props;
    nextMonth();
  };

  setPrevMonth = () => {
    const { prevMonth } = this.props;
    prevMonth();
  };

  render() {
    const { data, currentDate } = this.props;
    return (
      <>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" interval={0} />
            <YAxis />
            <Tooltip />

            <Bar dataKey="total">
              {data.map(entry => {
                return entry.month === currentDate ? (
                  <Cell key={`cell-${entry.month}`} fill={baseColors.primary} />
                ) : (
                  <Cell
                    key={`cell-${entry.month}`}
                    fill={baseColors.primary}
                  />
                );
              })}
            </Bar>
          </BarChart>
        </ResponsiveContainer>

        <div className="text-center">
          <Button
            variant="default"
            className="float-left"
            onClick={this.setPrevMonth}
          >
            <BsChevronLeft />
          </Button>
          <span>{currentDate}</span>
          <Button
            variant="default"
            className="float-right"
            onClick={this.setNextMonth}
          >
            <BsChevronRight />
          </Button>
        </div>
      </>
    );
  }
}
