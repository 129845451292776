import PropTypes from 'prop-types';
import React, { useState } from 'react';

const InputCountLetters = props => {
  const [text, setText] = useState('');

  const handleSave = e => {
    setText(e.target.value);
  };

  const { limit } = props;
  return (
    <div>
      <textarea maxLength={limit} value={text} onChange={handleSave} />
      <p
        style={{
          color: text.length <= limit - 10 ? 'black' : 'red'
        }}
      >
        {text.length / limit}
      </p>
    </div>
  );
};

InputCountLetters.propTypes = {
  limit: PropTypes.number
};

InputCountLetters.defaultProps = {
  limit: 120
};

export default InputCountLetters;
