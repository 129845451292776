import React, { useEffect } from 'react';
import { Field, getIn } from 'formik';
import { useSelector } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';
import {
  FormikCheckBox,
  FormikInput,
  UploadImage,
  FormikDatePicker
} from '../../components';

const UserForm = props => {
  const {
    settings: { removeParents, reorderProfile }
  } = useSelector(state => state.utils);
  const { errors, touched, setFieldValue, fromShow, user, values } = props;
  const {
    dateOfBirthFirstSon,
    dateOfBirthSecondSon,
    dateOfBirthThirdSon,
    fatherOrMother,
    birthdate
  } = values?.user;

  useEffect(() => {
    if (!fatherOrMother) {
      ['FirstSon', 'SecondSon', 'ThirdSon'].map(field =>
        setFieldValue(`user[dateOfBirth${field}]`, '')
      );
    }
  }, [fatherOrMother]);

  useEffect(() => {
    if (!dateOfBirthFirstSon) setFieldValue('user[dateOfBirthFirstSon]', '');
    if (!dateOfBirthSecondSon) setFieldValue('user[dateOfBirthSecondSon]', '');
    if (!dateOfBirthThirdSon) setFieldValue('user[dateOfBirthThirdSon]', '');
  }, [dateOfBirthFirstSon, dateOfBirthSecondSon, dateOfBirthThirdSon]);

  return (
    <>
      <Col
        md={fromShow ? { span: 2, offset: 1 } : 12}
        className={`mb-3 ${fromShow && 'img-align-center'}`}
      >
        {fromShow ? (
          <Card.Img src={user.avatarInfo?.fileUrl || ''} />
        ) : (
          <Field name="user[avatar]">
            {({ field }) => (
              <UploadImage
                {...field}
                name={user.avatarInfo?.filename}
                imageUrl={getIn(user.avatarInfo, 'fileUrl')}
                onChange={_avatar => setFieldValue(field.name, _avatar)}
                helpText="Formato sugerido 620x400px de máximo 10mb."
              />
            )}
          </Field>
        )}
      </Col>
      <Col md={{ span: 8, offset: 2 }} className="mt-3">
        <Row>
          <Col md={6}>
            <Field name="user[fullName]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Nombre"
                  disabled={fromShow}
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="user[email]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  disabled
                  label="Email"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="user[address]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Dirección para recibir premios"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          <Col md={6}>
            <Field name="user[birthdate]">
              {({ field }) => (
                <FormikDatePicker
                  showYearDropDown
                  selected={birthdate}
                  dateFormat="dd-MM-yyyy"
                  label="Fecha de nacimiento"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                  onChange={date => setFieldValue(field.name, date)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Field name="user[documentIdentity]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Documento de Identidad"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
          {reorderProfile === 1 ? (
            <Col md={6}>
              <Field name="user[whatsappNumber]">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    abbr
                    label="Número de WhatsApp"
                    inputType="number"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          ) : null}
          <Col md={6}>
            <Field name="user[instagram]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  label="Instagram"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </Col>
        </Row>
        <Row>
          {removeParents === 1 ? null : (
            <Col md={6} className="d-flex align-items-center mt-2">
              <Field name="user[fatherOrMother]">
                {({ field }) => (
                  <FormikCheckBox
                    {...field}
                    field={field}
                    label="¿Padre o Madre?"
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                  />
                )}
              </Field>
            </Col>
          )}
        </Row>
        {removeParents === 1 ? null : (
          <>
            <h5 className="my-3">¿Cuántos hijos tienes?</h5>
            <Row>
              <Col md={4}>
                <Field name="user[dateOfBirthFirstSon]">
                  {({ field }) => (
                    <FormikDatePicker
                      showYearDropDown
                      selected={dateOfBirthFirstSon}
                      dateFormat="dd-MM-yyyy"
                      label="Fecha de nacimiento primer hijo"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      onChange={date => setFieldValue(field.name, date)}
                      disabled={!fatherOrMother}
                      isClearable={dateOfBirthFirstSon !== '' && fatherOrMother}
                    />
                  )}
                </Field>
              </Col>
              <Col md={4}>
                <Field name="user[dateOfBirthSecondSon]">
                  {({ field }) => (
                    <FormikDatePicker
                      showYearDropDown
                      selected={dateOfBirthSecondSon}
                      dateFormat="dd-MM-yyyy"
                      label="Fecha de nacimiento segundo hijo"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      onChange={date => setFieldValue(field.name, date)}
                      disabled={!fatherOrMother}
                      isClearable={
                        dateOfBirthSecondSon !== '' && fatherOrMother
                      }
                    />
                  )}
                </Field>
              </Col>
              <Col md={4}>
                <Field name="user[dateOfBirthThirdSon]">
                  {({ field }) => (
                    <FormikDatePicker
                      showYearDropDown
                      selected={dateOfBirthThirdSon}
                      dateFormat="dd-MM-yyyy"
                      label="Fecha de nacimiento tercer hijo"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                      onChange={date => setFieldValue(field.name, date)}
                      disabled={!fatherOrMother}
                      isClearable={dateOfBirthThirdSon !== '' && fatherOrMother}
                    />
                  )}
                </Field>
              </Col>
            </Row>
          </>
        )}
      </Col>
    </>
  );
};

export default UserForm;
